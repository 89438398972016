import { Component } from '@angular/core';
import { environment } from '@clinician_environment';
import { NcIconService } from '@neurocare/ui';
import { DateService, ILoggingService } from '@neurocare/utils';

import {
  FeatureFlagKeys,
  FeatureFlagService,
} from './services/core/feature-flag.service';
import { StopwatchService } from './services/core/stopwatch.service';
import { VisibilityService } from './services/core/visibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'clinician';
  showFeatureFlagToggle: boolean;
  constructor(
    readonly loggingService: ILoggingService,
    readonly stopwatchService: StopwatchService,
    readonly iconService: NcIconService,
    readonly visibilityService: VisibilityService,
    readonly featureFlagService: FeatureFlagService,
    readonly dateService: DateService,
  ) {
    this.stopwatchService.startTimer();
    iconService.registerAllIcons('assets/icons');
    this.showFeatureFlagToggle = false;
    if (environment.e2e) {
      this.exposeServicesForTesting();
    }
  }

  toggleFeature(feature: FeatureFlagKeys) {
    this.featureFlagService.toggleFeature(feature);
  }

  exposeServicesForTesting() {
    (window as any)['dateService'] = this.dateService;
  }
}
